.custom-button:hover {
  filter: brightness(90%);
  cursor: pointer;
}
.custom-button:active {
  filter: brightness(80%);
  cursor: pointer;
}

body {
  background: black !important;
}
