.formDimmer .content {
  overflow: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.formDimmer.dimmer {
  align-items: flex-start !important;
  padding: 0px !important;
}

.hidden {
  display: none !important;
}

.fullDimmer {
  background-color: rgba(0, 0, 0, 1) !important;
}

.darkDimmer {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.extra-bold {
  text-shadow: 0.5px 0 currentColor;
  letter-spacing: 0.5px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.results {
  max-height: 300px;
  overflow: auto;
}

.hover {
  opacity: 1 !important;
  transition: ease-in-out 0.1s;
}

.button-left {
  margin: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-center {
  margin: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.button-right {
  margin: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.font-input {
  width: 60px !important;
  border-radius: 0px !important;
  margin: 0px !important;
}
.font-input input {
  border-radius: 0px !important;
}

.circle-bullet {
  width: 40px; /* Set the width of the circle */
  height: 40px; /* Set the height of the circle (same as width) */
  background-color: #ffffff; /* Circle background color */
  color: black; /* Text color */
  font-size: 24px; /* Size of the number inside */
  font-weight: bold; /* Bold text */
  text-align: center; /* Center text horizontally (optional with flexbox) */
  line-height: 40px; /* Center text vertically */
  border-radius: 50%; /* Make the div circular */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add a shadow */
}

.circle-bullet-large {
  width: 80px; /* Set the width of the circle */
  height: 80px; /* Set the height of the circle (same as width) */
  background-color: #ffffff; /* Circle background color */
  color: black; /* Text color */
  font-size: 24px; /* Size of the number inside */
  font-weight: bold; /* Bold text */
  text-align: center; /* Center text horizontally (optional with flexbox) */
  line-height: 80px; /* Center text vertically */
  border-radius: 50%; /* Make the div circular */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add a shadow */
}

.invite-text {
  margin: 0px !important;
}
